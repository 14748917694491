<template>
  <router-view />
</template>

<script>
// import "./assets/lib/wow/wow.min.js";
// import "./assets/lib/easing/easing.min.js";
// import "./assets/lib/waypoints/waypoints.min.js";
// import $ from "jquery";
// import $ from "jquery";
// import "./assets/lib/owlcarousel/owl.carousel.min.js";
// import "./assets/js/main.js";

export default {
  name: "App",
  components: {},
  mounted() {
    // Header carousel
    // $(document).ready(() => {
    //   // Header carousel
    //   $(".header-carousel").owlCarousel({
    //     autoplay: true,
    //     smartSpeed: 1500,
    //     items: 1,
    //     dots: false,
    //     loop: true,
    //     nav: true,
    //     navText: [
    //       '<i class="bi bi-chevron-left"></i>',
    //       '<i class="bi bi-chevron-right"></i>',
    //     ],
    //   });
    // });
  },
};
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
@import "assets/lib/animate/animate.min.css";
@import "assets/lib/owlcarousel/assets/owl.carousel.min.css";
@import "assets/css/bootstrap.min.css";
@import "assets/css/style.css";
</style>
<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
// body {
//   margin: 0px !important;
// }

// @import "assets/vendor/bootstrap/css/bootstrap-ar.min";
// @import "assets/scss/partials/_variables.css";
// @import "assets/scss/partials/_custom.scss";
</style>
